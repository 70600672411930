import React from 'react';
import Vehicle from '../components/Vehicle/Vehicle.js';

function VehicleInfo() {
    return (
        <div>
            <Vehicle />
        </div>
    );
}

export default VehicleInfo;
