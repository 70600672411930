import React from 'react';
import PhilliesSchedule from '../components/phillies/PhilliesSchedule.js';

function Phillies() {
    return (
        <div>
            <PhilliesSchedule />
        </div>
    );
}

export default Phillies;
